import React from 'react'
import RoundedLink from '@/components/RoundedLink'
import Moment from 'react-moment'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { excerpt } from '@/functions'

interface EventLiftData {
  event: {
    gwEventsDate: string
    gwEventsDateEnd: string
    gwEventsLocation: string
    gwEventsTime: string
    id: string
    gwEventsFirstSection: string
    title: string
    uri: string
    featuredImage: typeof GatsbyImage
  }
  heading?: string
  readMoreString?: string
  kloString?: string
}

const EventLift = ({ event, heading, readMoreString = 'Lue lisää', kloString = 'klo' }: EventLiftData) => {
  const eventpost: any = event

  return (
    <>
      {heading && <h2 className="text-4xl md:text-5xl wordbreak uppercase">{heading}</h2>}
      {eventpost.map((item: any) => {
        const featuredImageobj: any = getImage(item?.featuredImage?.node?.localFile)
        const alt: string = item?.featuredImage?.node?.altText

        return (
          <div key={item.id} className={`event-lift w-full mt-10 relative float-none overflow-hidden height-auto`}>
            <div
              className={`bg-green text-latte float-left ${featuredImageobj && 'lg:w-9/12 lg:mb-20'} ${
                !featuredImageobj && 'mb-10 lg:mb-20'
              }`}
            >
              <div className={`p-6 sm:p-12 ${featuredImageobj ? 'lg:pr-44 xl:pr-52' : null}`}>
                <div className="flex flex-wrap sm:flex-row flex-col mb-4 md:text-l lg:text-xl tracking-halffull">
                  {item.gwEventsDate && (
                    <span
                      className={`${
                        (item.gwEventsLocation || item.gwEventsTime) && 'after-line-event'
                      } flex items-center relative pr-6`}
                    >
                      <Moment format="DD.MM.YYYY" date={item.gwEventsDate} />
                      {item.gwEventsDateEnd && (
                        <>
                          &nbsp;-&nbsp;
                          <Moment format="DD.MM.YYYY" date={item.gwEventsDateEnd} />
                        </>
                      )}
                    </span>
                  )}
                  {item.gwEventsTime && (
                    <span className={`${item.gwEventsLocation && 'after-line-event'} flex items-center relative pr-6`}>
                      {kloString} {item.gwEventsTime}
                    </span>
                  )}
                  {item.gwEventsLocation && (
                    <span className={'pr-6 relative flex items-center'}>{item.gwEventsLocation}</span>
                  )}
                </div>
                <h2 className="text-3xl lg:text-4xl mb-4 break-words wordbreak">{excerpt(item.title, 10, true)}</h2>
                <p className="mb-6">{excerpt(item.gwEventsFirstSection, 30, true)}</p>
                <RoundedLink
                  className="border-latte text-latte hover:text-green hover:bg-latte focus:text-green focus:bg-latte"
                  to={item.uri}
                >
                  {readMoreString}
                </RoundedLink>
              </div>
            </div>
            {featuredImageobj && (
              <div className="w-full sm:w-1/2 lg:w-1/3 -ml-40 float-right sm:-mt-10 lg:mt-28 xl:mt-20 mb-10 lg:mb-20">
                <GatsbyImage className="" image={featuredImageobj} alt={alt ?? ''} />
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}

export default EventLift
